<template>
  <div id="leaseChange">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>日志管理</el-breadcrumb-item>
      <el-breadcrumb-item>套餐变动记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
            </el-input>
          </li>
          <li>
            <span class="label">停车场:</span>
            <el-select
              v-model="filtrate.parkingLotId"
              filterable
              placeholder="请选择"
              @change="
                getUserList();
                getList(1);
              "
            >
              <el-option
                v-for="item in parkingLotList"
                :key="item.parkingLotId"
                :label="item.parkingLotName"
                :value="item.parkingLotId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">操作账号:</span>
            <el-select
              v-model="filtrate.userId"
              placeholder="请选择"
              filterable
              clearable
              @change="getList(1)"
            >
              <el-option
                v-for="item in forceOutUserList"
                :key="item.userId"
                :label="item.username"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">变动类型:</span>
            <el-select
              v-model="filtrate.logType"
              filterable
              clearable
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option label="添加" value="添加" />
              <el-option label="删除" value="删除" />
              <el-option label="修改" value="修改" />
            </el-select>
          </li>
          <li>
            <span class="label">开始时间:</span>
            <el-date-picker
              v-model="filtrate.startTime"
              type="datetime"
              placeholder="选择开始时间"
              :clearable="false"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <span class="label">结束时间:</span>
            <el-date-picker
              v-model="filtrate.endTime"
              type="datetime"
              placeholder="选择结束时间"
              :clearable="false"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <ul>
            <li>
              <el-button type="primary" size="medium" @click="getList(1)"
                >查询
              </el-button>
            </li>
            <li>
              <el-button class="resetBtn" size="medium" @click="reset"
                >重置
              </el-button>
            </li>
          </ul>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
        :key="table.loading"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="expand-form">
              <el-form-item label="车牌号">
                <span>{{ props.row.current?.plateNum ?? "--" }}</span>
                <span>{{ props.row.prev?.plateNum ?? "--" }}</span>
              </el-form-item>
              <el-form-item label="租赁类型">
                <span>{{ props.row.current?.leaseType ?? "--" }}</span>
                <span>{{ props.row.prev?.leaseType ?? "--" }}</span>
              </el-form-item>
              <el-form-item label="套餐">
                <span>{{ props.row.current?.priceName ?? "--" }}</span>
                <span>{{ props.row.prev?.priceName ?? "--" }}</span>
              </el-form-item>
              <el-form-item label="分组">
                <span>{{ props.row.current?.seatGroupName ?? "--" }}</span>
                <span>{{ props.row.prev?.seatGroupName ?? "--" }}</span>
              </el-form-item>
              <el-form-item label="车辆归属">
                <span>{{ props.row.current?.subjectName ?? "--" }}</span>
                <span>{{ props.row.prev?.subjectName ?? "--" }}</span>
              </el-form-item>
              <el-form-item label="联系人">
                <span>{{ props.row.current?.contacts ?? "--" }}</span>
                <span>{{ props.row.prev?.contacts ?? "--" }}</span>
              </el-form-item>
              <el-form-item label="联系方式">
                <span>{{ props.row.current?.contactPhone ?? "--" }}</span>
                <span>{{ props.row.prev?.contactPhone ?? "--" }}</span>
              </el-form-item>
              <el-form-item label="备注">
                <span>{{ props.row.current?.remarks ?? "--" }}</span>
                <span>{{ props.row.prev?.remarks ?? "--" }}</span>
              </el-form-item>
              <el-form-item label="始终入场">
                <span>{{ props.row.current?.alwaysOpen ?? "--" }}</span>
                <span>{{ props.row.prev?.alwaysOpen ?? "--" }}</span>
              </el-form-item>
              <el-form-item
                label="时间范围"
                style="width: 200px; text-align: center"
              >
                <span v-if="!props.row.current?.leaseTimesFormat">--</span>
                <span
                  v-else
                  v-for="(time, timeIndex) of props.row.current
                    ?.leaseTimesFormat"
                  :key="timeIndex"
                  :style="{
                    color: time.notExpired ? 'red' : '',
                    width: '200px',
                  }"
                >
                  {{ time.format }}
                </span>

                <span v-if="!props.row.prev?.leaseTimesFormat">--</span>
                <span
                  v-else
                  v-for="(time, timeIndex) of props.row.prev?.leaseTimesFormat"
                  :key="timeIndex"
                  :style="{
                    color: time.notExpired ? 'red' : '',
                    width: '200px',
                  }"
                >
                  {{ time.format }}
                </span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column prop="plateNum" label="车牌号" align="center">
        </el-table-column>
        <el-table-column prop="logType" label="变动类型" align="center">
        </el-table-column>
        <el-table-column prop="username" label="操作用户" align="center">
        </el-table-column>
        <el-table-column prop="createAt" label="操作时间" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      passagewayList: [],
      deviceList: [],
      forceOutUserList: [],
      filtrate: {
        keyword: "",
        parkingLotId: "",
        userId: "",
        logType: "",
        startTime: this.$moment().subtract(14, "days"),
        endTime: new Date(),
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  watch: {
    parkingLotList: {
      handler(list) {
        if (list.length) {
          Object.assign(this.filtrate, {
            parkingLotId: list[0].parkingLotId,
          });
          this.getUserList();
          this.getList();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getUserList() {
      try {
        let res = await this.$http.post("/web/user/list/all", {
          parkingLotId: this.filtrate.parkingLotId,
        });
        if (res.code === 0) {
          this.forceOutUserList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    reset() {
      this.filtrate = {
        keyword: "",
        parkingLotId: this.parkingLotList.length
          ? this.parkingLotList[0].parkingLotId
          : "",
        userId: "",
        logType: "",
        startTime: this.$moment().subtract(14, "days"),
        endTime: new Date(),
      };
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/log/lease/change", {
          pageNum: page,
          pageSize: this.table.pageSize,
          ...this.filtrate,
          keyword: this.filtrate.keyword ? this.filtrate.keyword : null,
          userId: this.filtrate.userId ? this.filtrate.userId : null,
          logType: this.filtrate.logType ? this.filtrate.logType : null,
        });

        if (res.code === 0) {
          for (let item of res.data.list) {
            item.createAt = item.createAt
              ? this.$moment(item.createAt).format("YYYY-MM-DD HH:mm:ss")
              : "--";

            if (item.current && item.current.leaseTimes) {
              item.current.leaseTimesFormat = item.current.leaseTimes.map(
                (item) => {
                  return {
                    format: `${this.$moment(item.startTime).format(
                      "yyyy-MM-DD HH:mm:ss"
                    )} ~ ${
                      item.endTime
                        ? this.$moment(item.endTime).format(
                            "yyyy-MM-DD HH:mm:ss"
                          )
                        : ""
                    }`,
                    notExpired: this.$moment(item.endTime).isBefore(
                      this.$moment()
                    ),
                  };
                }
              );
            }
            if (item.prev && item.prev.leaseTimes) {
              item.prev.leaseTimesFormat = item.prev.leaseTimes.map((item) => {
                return {
                  format: `${this.$moment(item.startTime).format(
                    "yyyy-MM-DD HH:mm:ss"
                  )} ~ ${
                    item.endTime
                      ? this.$moment(item.endTime).format("yyyy-MM-DD HH:mm:ss")
                      : ""
                  }`,
                  notExpired: this.$moment(item.endTime).isBefore(
                    this.$moment()
                  ),
                };
              });
            }
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#leaseChange {
  .expand-form {
    display: flex;
    justify-content: space-between;
    .el-form-item {
      flex: 1;
      text-align: center;
      margin: 0;
      .el-form-item__label {
        padding-right: 0;
        color: white;
      }
      .el-form-item__content {
        display: block;
        span {
          display: block;
          &:nth-child(1) {
            color: white;
          }
        }
      }
    }
  }
}
</style>
